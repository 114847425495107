<template>
  <div>
    <div class="tab-container row">
      <Tab
        v-for="(tab, index) in tabs"
        :key="index"
        :label="tab.label"
        :isSelected="tabSelected == tab.slug"
        @selectTab="selectTab(tab.slug)"
      />
    </div>
  </div>
</template>

<script>
import Tab from "@/components/Tabs/Tab.vue";

export default {
  name: "TabContainer",
  components: {
    Tab,
  },
  props: {
    tabs: {
      type: Array,
    },
  },
  data() {
    return {
      tabSelected: this.tabs[0].slug,
    };
  },
  methods: {
    selectTab(slug) {
      this.tabSelected = slug;
      this.$emit("selectedTab", this.tabSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Tabs";
</style>
