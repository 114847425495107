<template>
  <gmap-autocomplete
    @place_changed="action"
    :options="{
      fields: ['geometry', 'formatted_address', 'address_components'],
      componentRestrictions: { country: country },
    }"
    :class="inputClass"
    :placeholder="placeholder"
  >
  </gmap-autocomplete>
</template>

<script>
import { country } from "@/services/country";
export default {
  name: "InputGMap",
  props: {
    inputClass: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  data() {
    return {
      country,
    };
  },
  methods: {
    action(value) {
      this.$emit("action", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Inputs";
</style>
