<template>
  <div class="dealer-info" v-if="Object.entries(info).length !== 0">
    <CloseIcon :isButton="true" @action="closeInfo" />
    <p>
      <span class="text-white-highlight">{{ info.dealerName }}</span
      ><br />
      <span class="text-white">{{ info.dealerAddress }}</span
      ><br />
      <span class="text-white">{{ info.dealerPostalCode }}</span>
    </p>
    <div>
      <Button
        :label="translateString('select')"
        btnClass="map-button "
        @action="selectDealer"
      />
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icons/CloseIcon.vue";
import Button from "@/components/Buttons/Button.vue";

import { translateString } from "@/functions/functions.js";

export default {
  name: "DealerInfo",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Button,
    CloseIcon,
  },
  methods: {
    translateString,
    selectDealer() {
      this.$emit("selectDealer", {
        dealerID: this.info.dealerId,
        dealerName: this.info.dealerName,
        dealerAddress: this.info.dealerAddress,
        dealerPostalCode: this.info.dealerPostalCode,
        dealerInterval: this.info.dealerInterval,
        hasCampaigns: this.info.hasCampaigns,
      });
    },
    closeInfo() {
      this.$emit("closeInfo");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/MapsElements";
</style>
