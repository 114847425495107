<script async defer src="https://maps.googleapis.com/maps/api/js"></script>

<template>
  <div class="dealer-selection">
    <Header
      :isHeader="true"
      :back="true"
      :carInfo="true"
      :step="currentStepIndex"
    />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar />
      </template>
      <Title titleStyle="title" :text="translateString('selectDealer')" />
      <TabContainer :tabs="tabs" @selectedTab="selectTab" />
      <div class="row">
        <InputGMap
          inputClass="simple"
          :placeholder="translateString('currentLocation')"
          @action="setCurrentPlace($event)"
        />
        <ButtonIcon @action="checkLocationPermission">
          <PinIcon />
        </ButtonIcon>
      </div>
      <div class="align-mb" id="list">
        <CheckboxContainer
          v-for="(dealer, index) in listDealers"
          :key="index"
          :value="dealer.dealerName"
          :id="'dealer-' + dealer.dealerId"
          checkboxClass="checkbox-round"
          containerClass="left align-top"
          inputType="radio"
          name="dealer"
          :isChecked="
            markingServiceSelected &&
            Number(markingServiceSelected.dealerId) === Number(dealer.dealerId)
          "
          @updateInput="selectDealerList(dealer)"
          :urlPhoto="dealer.urlphoto"
        >
          <LabelComplex :forLabel="'dealer-' + dealer.dealerId">
            <p class="p-container">
              <span class="text-highlight">{{ dealer.dealerName }}</span
              ><br />
              <span class="text">{{ dealer.dealerAddress }}</span
              ><br />
              <span class="text">{{ dealer.dealerPostalCode }}</span>
              <br />
              <img
                v-if="isDesktop"
                class="position-urlphoto"
                :src="getDealerPhoto(dealer.dealerId)"
                alt=""
              />
            </p>
          </LabelComplex>
        </CheckboxContainer>
        <Button
          v-if="sortedDealers.length > 4"
          :label="showLabel"
          btnClass="btn-tertiary align-btn-1 "
          @action="seeAll()"
        />
      </div>
      <div id="map" class="contact-form--wrapper" style="display: none">
        <gmap-map
          :center="center"
          :options="mapStyle"
          ref="gmap"
          style="width: 100%; height: 400px"
          :class="lookingForLocation ? 'disabled' : ''"
        >
          <gmap-marker
            v-for="(marker, index) in markers"
            :key="index"
            :position="marker.position"
            @click="openDealerInfo(marker.dealerInfo)"
            :icon="
              dealerSelected.dealerID === marker.dealerInfo.dealerId
                ? iconSelectedDealer
                : iconDealer
            "
          ></gmap-marker>
          <gmap-marker :position="userMarker" :icon="iconUser"></gmap-marker>
          <DealerInfo
            :info="dealerInfo"
            @selectDealer="selectDealerMap($event)"
            @closeInfo="closeInfo"
          />
        </gmap-map>
      </div>
      <template v-slot:footer>
        <Footer>
          <Button
            id="getServices"
            class="btn-primary align-btn-getservices"
            :label="translateString('continue')"
            :btnClass="ctaBtn"
            @action="next('getServices')"
          />
        </Footer>
      </template>
    </Wrapper>
    <transition name="modal">
      <Modal
        v-if="!locationPermission"
        :msg="errorMessage"
        :isActive="!locationPermission"
      />
    </transition>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import ButtonIcon from "@/components/Buttons/ButtonIcon.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import DealerInfo from "@/components/MapsElements/DealerInfo.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import InputGMap from "@/components/Inputs/InputGMap.vue";
import LabelComplex from "@/components/Checkbox/LabelComplex.vue";
import Modal from "@/components/Modal/Modal.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import TabContainer from "@/components/Tabs/TabContainer.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";

import { mapStyling } from "@/localData/mapStyling.js";
import { getDealersByRange } from "@/functions/location.js";
import { translateString } from "@/functions/functions.js";
import { mapGetters, mapState } from "vuex";

import PinIcon from "@/assets/icons/PinIcon.vue";

import selectedDealerMarker from "@/assets/icons/pin-selected.png";
import dealerMarker from "@/assets/icons/pin.png";
import userMarker from "@/assets/icons/userMarker.png";

import { gmapApi } from "vue2-google-maps";

export default {
  name: "DealerSelection",
  components: {
    Button,
    ButtonIcon,
    CheckboxContainer,
    DealerInfo,
    Footer,
    Header,
    InputGMap,
    LabelComplex,
    Modal,
    PinIcon,
    Sidebar,
    TabContainer,
    Title,
    Wrapper,
  },
  props: {
    urlPhoto: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dealersList: [],
      iconSelectedDealer: { url: selectedDealerMarker },
      iconDealer: { url: dealerMarker },
      iconUser: { url: userMarker },
      tabs: [
        {
          label: translateString("list"),
          slug: "lista",
        },
        {
          label: translateString("map"),
          slug: "mapa",
        },
      ],
      selectedTab: "lista",

     /*  ctaBtn: this.$store.state.dealerSelected.length == 0 ? "disabled  " : "", */

      center: this.$store.state.lastLocation,
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      currentPlace: null,
      markers: [],
      allDealers: this.$store.state.dealersByBrand,
      sortedDealers: this.$store.state.dealersFiltered,
      dealerInfo: {},
      map: null,
      mapStyle: {
        //disableDefaultUI: true,
        styles: mapStyling,
        zoom: 10,
        maxZoom: 15,
      },
      userMarker: this.$store.state.lastLocation,
      locationPermission: true,
      errorMessage: "",
      lookingForLocation: false,
      bounds: {},
      showAll: false,
      showLabel: translateString("seeMore"),
      hasServicesSelected: false,
      filteredDealers: [],
      previousDealerSelected: null,
    };
  },
  computed: {
    ctaBtn() {
      return (this.markingServiceSelected && this.markingServiceSelected.dealerId) || (this.$store.state.dealerSelected && this.$store.state.dealerSelected.dealerID) ? "" : "disabled ";
    },
    isDesktop() {
      return window.matchMedia("(min-width: 769px)").matches;
    },
    finalError() {
      return this.$store.state.finalError;
    },
    listDealers() {
      if (this.showAll) {
        return this.sortedDealers;
      } else {
        return this.sortedDealers.slice(0, 4);
      }
    },
    servicesReady() {
      return this.$store.state.servicesReady;
    },
    dealerSelected() {
      return this.$store.state.dealerSelected;
    },
    google: gmapApi,
    ...mapGetters(["getCurrentStepIndex"]),

    ...mapState(["markingServiceSelected"]),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
    /*  selectedDealers() {
      return this.filteredDealers;
    }, */
    isDesktop() {
      return window.matchMedia("(min-width: 769px)").matches;
    },
  },
  mounted() {
    if (this.markingServiceSelected) {
      this.sortDealers();
      this.checkMarkingServiceSelected();
    }
  },
  created() {
    this.$store.dispatch("clearServices");

    // aqui eu filtrarei as mensagens de aviso do console acerca do deprecated do google.maps.Marker
    function filterConsoleWarnings() {
      const originalConsoleWarn = console.warn;
      console.warn = function (message, ...optionalParams) {
        //aqui são as mensagens que estou a remover do console do browser, a primeira e devido a falta de atualizacão da lib vue2 do google maps e a segunda é devido a falta de async no carregamento do google maps
        if (
          typeof message === "string" &&
          (message.includes("google.maps.Marker is deprecated") ||
            (typeof message === "string" &&
              message.includes(
                "Google Maps JavaScript API has been loaded directly without loading=async"
              )))
        ) {
          // Ignorando a mensagem de depreciação
          return;
        }
        // Chamar o console.warn original para todas as outras mensagens
        originalConsoleWarn.call(console, message, ...optionalParams);
      };
    }

    // Chamarei a função para filtrar as mensagens de aviso
    filterConsoleWarnings();
    //Verificar se o google esta definido e mostro a lista com dealer marcado do agendamento se tiver markingServiceSelected.
    if (
      this.markingServiceSelected &&
      this.markingServiceSelected.lstServicesId
    ) {
      if (this.markingServiceSelected.lstServicesId.length > 0) {
        this.setCurrentPlace(this.currentPlace, this.markingServiceSelected);
      }
    }
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    this.$store.dispatch("clearSelectedInfo", "DealerSelection");
  },
  methods: {
    translateString,
    updateDealerId(dealerId) {
      if (this.markingServiceSelected) {
        this.markingServiceSelected.dealerId = dealerId;
      } else if (this.$store.state.dealerSelected) {
        this.$store.commit('UPDATE_DEALER_ID', dealerId);
      }
    },
    // Método para ordenar os revendedores para o que vier como agendamento, ser sempre o primeiro a ser mostrado.
    sortDealers() {
      if (this.markingServiceSelected) {
        this.sortedDealers.sort((a, b) => {
          if (Number(a.dealerId) === Number(this.markingServiceSelected.dealerId)) {
            return -1;
          }
          if (Number(b.dealerId) === Number(this.markingServiceSelected.dealerId)) {
            return 1;
          }
          return 0;
        });
      }
    },
    getDealerPhoto(dealerId) {
      const dealer = this.allDealers.find((d) => d.id === dealerId);
      return dealer &&
        dealer.urlphoto !==
          "https://empresas.gruposalvadorcaetano.pt/files/sitegsc/companyfiles/"
        ? dealer.urlphoto
        : null;
    },
    checkMarkingServiceSelected() {
      let selectedDealers = []; // Defino selectedDealers fora do bloco if

      if (this.markingServiceSelected) {
        this.getDealer(this.markingServiceSelected);
        const currentListDealers = this.listDealers.slice();

        selectedDealers = currentListDealers.filter((dealer) => {
          if (
            this.markingServiceSelected &&
            Number(this.markingServiceSelected.dealerId) ===
              Number(dealer.dealerId)
          ) {
            this.selectDealerList(dealer);
            this.hasServicesSelected = true;
            return true;
          }
          return false;
        });
        this.filteredDealers = selectedDealers;
      }

      return selectedDealers;
    },
    getDealer(selectedDealer) {
      // Verifico se listDealers e markingServiceSelected estão disponíveis
      if (!this.listDealers || !this.markingServiceSelected) {
        return;
      }

      let updatedDealers = this.listDealers.map((dealer) => {
        if (
          Number(dealer.dealerId) ===
          Number(this.markingServiceSelected.dealerId)
        ) {
          dealer.selected = true;
        }
        return dealer;
      });
    },
    seeAll() {
      this.showAll = !this.showAll;
      this.showLabel = translateString(this.showAll ? "seeLess" : "seeMore");
    },
    selectTab(slug) {
      this.selectedTab = slug;
    },
    checkLocationPermission() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
        this.lookingForLocation = true;
      } else {
        this.locationPermission = false;
        this.errorMessage = translateString("gelocationNotSupported");
      }
    },
    //GET USER'S CURRENT LOCATION
    showPosition(position) {
      this.markers = [];

      this.userMarker = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      this.locationPermission = true;
      this.lookingForLocation = false;

      if (typeof google != "undefined") {
        this.findDealers();
      }
    },
    showError(error) {
      this.locationPermission = false;
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.errorMessage = translateString("locationBlocked");
          break;
        case error.POSITION_UNAVAILABLE:
          this.errorMessage = translateString("locationUnavailable");
          break;
        case error.TIMEOUT:
          this.errorMessage = translateString("locationFailed");
          break;
        case error.UNKNOWN_ERROR:
          this.errorMessage = translateString("unknownError");
          break;
      }
      this.lookingForLocation = false;
      setTimeout(() => {
        this.locationPermission = true;
      }, 5000);
    },
    setCurrentPlace(place, selectedDealer) {
      this.currentPlace = place;
      this.markers = [];

      if (this.currentPlace) {
        this.userMarker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
      }

      this.findDealers(selectedDealer);
    },
    findDealers(selectedDealer) {
      this.$store.dispatch("selectOption", {
        selectedValue: "lastLocation",
        value: this.userMarker,
      });

      this.sortedDealers = getDealersByRange(this.allDealers, this.userMarker);

      this.createBounds();

      this.$store.dispatch("selectOption", {
        selectedValue: "dealersFiltered",
        value: this.sortedDealers,
      });
    },
    createBounds() {
      this.bounds = new google.maps.LatLngBounds();

      this.bounds.extend(
        new google.maps.LatLng(this.userMarker.lat, this.userMarker.lng)
      );

      this.sortedDealers.forEach((dealer) => {
        this.addMarker(dealer);
      });
      this.sortedDealers.slice(0, 4).forEach((dealer) => {
        this.addToBounds(dealer);
      });

      if (this.$refs.gmap) {
        this.$refs.gmap.$mapPromise.then((map) => {
          map.fitBounds(this.bounds);
          this.center = {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          };
          this.mapStyle.zoom = map.getZoom();
        });
      }
    },
    addMarker(dealer) {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(dealer.lat, dealer.lng),
        dealerInfo: dealer,
      });

      this.markers.push(marker);
    },
    addToBounds(dealer) {
      this.bounds.extend(new google.maps.LatLng(dealer.lat, dealer.lng));
    },
    selectDealerList(value) {
      const dealerSelected = {
        dealerID: value.dealerId,
        dealerName: value.dealerName,
        dealerAddress: value.dealerAddress,
        dealerPostalCode: value.dealerPostalCode,
        dealerInterval: value.dealerInterval,
        hasCampaigns: value.hasCampaigns,
      };
      this.$store.commit("UPDATE_DEALER_SELECTED", dealerSelected);
      this.$store.dispatch("selectOption", {
        selectedValue: "dealerSelected",
        value: dealerSelected,
      });
      this.$store.state.dealerSelected = dealerSelected; //vou atualizar o watch
      this.$store.state.servicesReady = false; //vou atualizar para fazer um novo pedido do getServices quando mudar o dealer.
      this.$store.dispatch("selectOption", {
        selectedValue: "hasCampaigns",
        value: value.hasCampaigns,
      });
      this.$store.dispatch("clearSelectedInfo", "Services");
    },
    selectDealerMap(value) {
      this.$store.state.dealerSelected = this.dealerInfo;
      this.$store.dispatch("selectOption", {
        selectedValue: "dealerSelected",
        value: value,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "hasCampaigns",
        value: value.hasCampaigns,
      });
      this.closeInfo();
      this.$store.dispatch("clearSelectedInfo", "Services");
    },
    closeInfo() {
      this.dealerInfo = {};
    },
    openDealerInfo(dealer) {
      this.dealerInfo = dealer;
    },
    next(id) {
      if (this.servicesReady) {
        this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
        this.$router.push({ name: "Services" });
      } else {
        //primeiro momento
        this.$store.dispatch("clearSelectedInfo", "Services");
        this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
        this.$store.dispatch(
          "getServices",
          this.$store.state.dealerSelected.dealerID
        );
        // Atualize a store com o revendedor selecionado
        this.$store.state.dealerSelected = this.$store.state.dealerSelected;
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
        this.$store.dispatch("clearSelectedInfo", "Services");
      }
    },
    updateCalendar(dealer) {
      this.$store.commit("UPDATE_DEALER_SELECTED", dealer);
    },
  },
  watch: {
    dealerSelected(newDealer, oldDealer) {
      this.updateDealerId(newDealer.dealerID);
      this.$store.commit("RESET_SELECTED_SERVICES");
      this.$store.dispatch("selectOption", {
        selectedValue: "updatableServicesTotal",
        value: "",
      });

      // Se o dealer selecionado mudou, atualizo o calendário
      if (newDealer !== oldDealer) {
        this.previousDealerSelected = oldDealer;
        this.updateCalendar(newDealer);
      }
    },
    servicesReady() {
      if (this.servicesReady == true) {
        this.$router.push({ name: "Services" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    selectedTab() {
      if (this.selectedTab == "mapa") {
        if (Object.keys(this.bounds).length > 0 && this.$refs.gmap) {
          this.$refs.gmap.$mapPromise.then((map) => {
            map.fitBounds(this.bounds);
            this.center = {
              lat: map.getCenter().lat(),
              lng: map.getCenter().lng(),
            };
            this.mapStyle.zoom = map.getZoom();
          });
        }
        document.querySelector("#map").style.display = "block";
        document.querySelector("#list").style.display = "none";
      } else {
        document.querySelector("#list").style.display = "block";
        document.querySelector("#map").style.display = "none";
      }
    },
    google() {
      if (this.google) {
        this.checkLocationPermission();
        //this.findDealers();
        this.createBounds();
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
<!-- style adicionado exclusivamente para a transition do modal de erro -->
<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.7s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
.p-container {
  width: 421px;
  height: 109px;
}
.position-urlphoto {
  width: 178px;
  max-height: 100px;
  display: flex;
  justify-content: flex-end;
  margin-left: 220%;
  position: relative;
  bottom: 70px;
  border-radius: 12px;
  margin-right: 20px;
}

@media screen and (min-width: 800px) and (max-width: 1919px) {
  .position-urlphoto {
    margin-left: 165%;
  }
}
</style>
