<template>
  <div>
    <button :class="btnClass + 'btn-icon'" @click="action()">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    btnClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Buttons";
</style>
