const geolib = require("geolib");

export function getDealersByRange(dealers, userLocation) {
  const sortedDealers = [];
  dealers.forEach((dealer) => {
    const lat = dealer.cordY;
    const lng = dealer.cordX;
    const dealerLocation = { lat, lng };
    const distance = getMatrixDistance(dealerLocation, userLocation);
    if (!distance) return null;
    const service = {
      dealerId: dealer.id,
      dealerName: dealer.name,
      dealerAddress: dealer.address,
      dealerDistance: Math.round(distance / 1000), // m to km
      dealerCoordinates: `${lat},${lng}`,
      dealerPostalCode: dealer.cp + " " + dealer.cpext,
      dealerInterval: dealer.interval,
      lat: lat,
      lng: lng,
      hasCampaigns: dealer.hasCampaigns,
    };

    sortedDealers.push(service);
  });

  sortedDealers.sort((a, b) => (a.dealerDistance > b.dealerDistance ? 1 : -1));
  return sortedDealers;
}

function getMatrixDistance(origins, destinations) {
  if (origins.lat && origins.lng) {
    let result = geolib.getDistance(origins, destinations, 1);
    return result;
  }
  return null;
}
