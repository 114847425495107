<template>
  <div :class="selectedClass + 'tab'" @click="selectTab($event)">
    <p class="sm-text-highlight-upper">{{ label }}</p>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    label: {
      type: String,
      default: "",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedClass() {
      return this.isSelected ? "selected " : "";
    },
  },
  methods: {
    selectTab() {
      this.$emit("selectTab");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Tabs";
</style>
